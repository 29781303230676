.tox {
  box-shadow: none;
  box-sizing: content-box;
  color: #222f3e;
  cursor: auto;
  -webkit-tap-highlight-color: transparent;
  text-shadow: none;
  text-transform: none;
  vertical-align: initial;
  white-space: normal;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.tox :not(svg):not(rect) {
  box-sizing: inherit;
  color: inherit;
  cursor: inherit;
  direction: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  text-shadow: inherit;
  text-transform: inherit;
  vertical-align: inherit;
  white-space: inherit;
  box-shadow: none;
  float: none;
  background: none;
  border: 0;
  outline: 0;
  width: auto;
  max-width: none;
  height: auto;
  margin: 0;
  padding: 0;
  position: static;
}

.tox:not([dir="rtl"]) {
  text-align: left;
  direction: ltr;
}

.tox[dir="rtl"] {
  text-align: right;
  direction: rtl;
}

.tox-tinymce {
  box-shadow: none;
  box-sizing: border-box;
  border: 2px solid #eee;
  border-radius: 10px;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
  visibility: inherit !important;
}

.tox.tox-tinymce-inline {
  box-shadow: none;
  overflow: initial;
  border: none;
}

.tox.tox-tinymce-inline .tox-editor-container {
  overflow: initial;
}

.tox.tox-tinymce-inline .tox-editor-header {
  box-shadow: none;
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}

.tox-tinymce-aux {
  z-index: 1300;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
}

.tox-tinymce :focus, .tox-tinymce-aux :focus {
  outline: 0;
}

button::-moz-focus-inner {
  border: 0;
}

.tox[dir="rtl"] .tox-icon--flip svg {
  transform: rotateY(180deg);
}

.tox .accessibility-issue__header {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.tox .accessibility-issue__description {
  border-radius: 6px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.tox .accessibility-issue__description > div {
  padding-bottom: 4px;
}

.tox .accessibility-issue__description > div > div {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.tox .accessibility-issue__description > div > div .tox-icon svg {
  display: block;
}

.tox .accessibility-issue__repair {
  margin-top: 16px;
}

.tox .tox-dialog__body-content .accessibility-issue--info .accessibility-issue__description {
  color: #222f3e;
  background-color: #0065d81a;
}

.tox .tox-dialog__body-content .accessibility-issue--info .tox-form__group h2 {
  color: #006ce7;
}

.tox .tox-dialog__body-content .accessibility-issue--info .tox-icon svg {
  fill: #006ce7;
}

.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon {
  color: #fff;
  background-color: #006ce7;
}

.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:focus, .tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:hover {
  background-color: #0060ce;
}

.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:active {
  background-color: #0054b4;
}

.tox .tox-dialog__body-content .accessibility-issue--warn .accessibility-issue__description {
  color: #222f3e;
  background-color: #ffa50014;
}

.tox .tox-dialog__body-content .accessibility-issue--warn .tox-form__group h2 {
  color: #8f5d00;
}

.tox .tox-dialog__body-content .accessibility-issue--warn .tox-icon svg {
  fill: #8f5d00;
}

.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon {
  color: #222f3e;
  background-color: #ffe89d;
}

.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:focus, .tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:hover {
  color: #222f3e;
  background-color: #f2d574;
}

.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:active {
  color: #222f3e;
  background-color: #e8c657;
}

.tox .tox-dialog__body-content .accessibility-issue--error .accessibility-issue__description {
  color: #222f3e;
  background-color: #cc00001a;
}

.tox .tox-dialog__body-content .accessibility-issue--error .tox-form__group h2 {
  color: #c00;
}

.tox .tox-dialog__body-content .accessibility-issue--error .tox-icon svg {
  fill: #c00;
}

.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon {
  color: #222f3e;
  background-color: #f2bfbf;
}

.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:focus, .tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:hover {
  color: #222f3e;
  background-color: #e9a4a4;
}

.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:active {
  color: #222f3e;
  background-color: #ee9494;
}

.tox .tox-dialog__body-content .accessibility-issue--success .accessibility-issue__description {
  color: #222f3e;
  background-color: #78ab461a;
}

.tox .tox-dialog__body-content .accessibility-issue--success .accessibility-issue__description > :last-child {
  display: none;
}

.tox .tox-dialog__body-content .accessibility-issue--success .tox-form__group h2 {
  color: #527530;
}

.tox .tox-dialog__body-content .accessibility-issue--success .tox-icon svg {
  fill: #527530;
}

.tox .tox-dialog__body-content .accessibility-issue__header .tox-form__group h1, .tox .tox-dialog__body-content .tox-form__group .accessibility-issue__description h2 {
  margin-top: 0;
  font-size: 14px;
}

.tox:not([dir="rtl"]) .tox-dialog__body-content .accessibility-issue__header .tox-button {
  margin-left: 4px;
}

.tox:not([dir="rtl"]) .tox-dialog__body-content .accessibility-issue__header > :nth-last-child(2) {
  margin-left: auto;
}

.tox:not([dir="rtl"]) .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 4px 4px 8px;
}

.tox[dir="rtl"] .tox-dialog__body-content .accessibility-issue__header .tox-button {
  margin-right: 4px;
}

.tox[dir="rtl"] .tox-dialog__body-content .accessibility-issue__header > :nth-last-child(2) {
  margin-right: auto;
}

.tox[dir="rtl"] .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 8px 4px 4px;
}

.tox .tox-advtemplate .tox-form__grid {
  flex: 1;
}

.tox .tox-advtemplate .tox-form__grid > div:first-child {
  flex-direction: column;
  width: 30%;
  display: flex;
}

.tox .tox-advtemplate .tox-form__grid > div:first-child > div:nth-child(2) {
  flex-grow: 1;
  flex-basis: 0;
  overflow: auto;
}

@media only screen and (width <= 767px) {
  body:not(.tox-force-desktop) .tox .tox-advtemplate .tox-form__grid > div:first-child {
    width: 100%;
  }
}

.tox .tox-advtemplate iframe {
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 0 10px;
}

.tox .tox-anchorbar, .tox .tox-bottom-anchorbar, .tox .tox-bar {
  flex: none;
  display: flex;
}

.tox .tox-button {
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  background-color: #006ce7;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border: 1px solid #006ce7;
  border-radius: 6px;
  outline: 0;
  margin: 0;
  padding: 4px 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.tox .tox-button:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  border-radius: 6px;
  position: absolute;
  inset: -1px;
  box-shadow: inset 0 0 0 2px #fff, 0 0 0 1px #006ce7, 0 0 0 3px #006ce740;
}

.tox .tox-button[disabled] {
  box-shadow: none;
  color: #ffffff80;
  cursor: not-allowed;
  background-color: #006ce7;
  background-image: none;
  border-color: #006ce7;
}

.tox .tox-button:focus:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background-color: #0060ce;
  background-image: none;
  border-color: #0060ce;
}

.tox .tox-button:focus-visible:not(:disabled):before {
  opacity: 1;
}

.tox .tox-button:hover:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background-color: #0060ce;
  background-image: none;
  border-color: #0060ce;
}

.tox .tox-button:active:not(:disabled), .tox .tox-button.tox-button--enabled {
  box-shadow: none;
  color: #fff;
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
}

.tox .tox-button.tox-button--enabled[disabled] {
  box-shadow: none;
  color: #ffffff80;
  cursor: not-allowed;
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
}

.tox .tox-button.tox-button--enabled:focus:not(:disabled), .tox .tox-button.tox-button--enabled:hover:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background-color: #00489b;
  background-image: none;
  border-color: #00489b;
}

.tox .tox-button.tox-button--enabled:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background-color: #003c81;
  background-image: none;
  border-color: #003c81;
}

.tox .tox-button--icon-and-text, .tox .tox-button.tox-button--icon-and-text, .tox .tox-button.tox-button--secondary.tox-button--icon-and-text {
  padding: 5px 4px;
  display: flex;
}

.tox .tox-button--icon-and-text .tox-icon svg, .tox .tox-button.tox-button--icon-and-text .tox-icon svg, .tox .tox-button.tox-button--secondary.tox-button--icon-and-text .tox-icon svg {
  fill: currentColor;
  display: block;
}

.tox .tox-button--secondary {
  box-shadow: none;
  color: #222f3e;
  letter-spacing: normal;
  text-transform: none;
  background-color: #f0f0f0;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  outline: 0;
  padding: 4px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}

.tox .tox-button--secondary[disabled] {
  box-shadow: none;
  color: #222f3e80;
  background-color: #f0f0f0;
  background-image: none;
  border-color: #f0f0f0;
}

.tox .tox-button--secondary:focus:not(:disabled), .tox .tox-button--secondary:hover:not(:disabled) {
  box-shadow: none;
  color: #222f3e;
  background-color: #e3e3e3;
  background-image: none;
  border-color: #e3e3e3;
}

.tox .tox-button--secondary:active:not(:disabled) {
  box-shadow: none;
  color: #222f3e;
  background-color: #d6d6d6;
  background-image: none;
  border-color: #d6d6d6;
}

.tox .tox-button--secondary.tox-button--enabled {
  box-shadow: none;
  color: #222f3e;
  background-color: #a8c8ed;
  background-image: none;
  border-color: #a8c8ed;
}

.tox .tox-button--secondary.tox-button--enabled[disabled] {
  box-shadow: none;
  color: #222f3e80;
  background-color: #a8c8ed;
  background-image: none;
  border-color: #a8c8ed;
}

.tox .tox-button--secondary.tox-button--enabled:focus:not(:disabled), .tox .tox-button--secondary.tox-button--enabled:hover:not(:disabled) {
  box-shadow: none;
  color: #222f3e;
  background-color: #93bbe9;
  background-image: none;
  border-color: #93bbe9;
}

.tox .tox-button--secondary.tox-button--enabled:active:not(:disabled) {
  box-shadow: none;
  color: #222f3e;
  background-color: #7daee4;
  background-image: none;
  border-color: #7daee4;
}

.tox .tox-button--icon, .tox .tox-button.tox-button--icon, .tox .tox-button.tox-button--secondary.tox-button--icon {
  padding: 4px;
}

.tox .tox-button--icon .tox-icon svg, .tox .tox-button.tox-button--icon .tox-icon svg, .tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg {
  fill: currentColor;
  display: block;
}

.tox .tox-button-link {
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  background: 0;
  border: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  display: inline-block;
}

.tox .tox-button-link--sm {
  font-size: 14px;
}

.tox .tox-button--naked {
  box-shadow: unset;
  color: #222f3e;
  background-color: #0000;
  border-color: #0000;
}

.tox .tox-button--naked[disabled] {
  box-shadow: unset;
  color: #222f3e80;
  background-color: #222f3e1f;
  border-color: #0000;
}

.tox .tox-button--naked:hover:not(:disabled), .tox .tox-button--naked:focus:not(:disabled) {
  box-shadow: unset;
  color: #222f3e;
  background-color: #222f3e1f;
  border-color: #0000;
}

.tox .tox-button--naked:active:not(:disabled) {
  box-shadow: unset;
  color: #222f3e;
  background-color: #222f3e2e;
  border-color: #0000;
}

.tox .tox-button--naked .tox-icon svg {
  fill: currentColor;
}

.tox .tox-button--naked.tox-button--icon:hover:not(:disabled) {
  color: #222f3e;
}

.tox .tox-checkbox {
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  min-width: 36px;
  height: 36px;
  display: flex;
}

.tox .tox-checkbox__input {
  width: 1px;
  height: 1px;
  position: absolute;
  top: auto;
  overflow: hidden;
}

.tox .tox-checkbox__icons {
  box-sizing: content-box;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 3px;
  display: flex;
  box-shadow: 0 0 0 2px #0000;
}

.tox .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  fill: #222f3e4d;
  display: block;
}

.tox .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg, .tox .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  fill: #006ce7;
  display: none;
}

.tox .tox-checkbox--disabled {
  color: #222f3e80;
  cursor: not-allowed;
}

.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__checked svg, .tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__unchecked svg, .tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  fill: #222f3e80;
}

.tox input.tox-checkbox__input:checked + .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: none;
}

.tox input.tox-checkbox__input:checked + .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  display: block;
}

.tox input.tox-checkbox__input:indeterminate + .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: none;
}

.tox input.tox-checkbox__input:indeterminate + .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  display: block;
}

.tox input.tox-checkbox__input:focus + .tox-checkbox__icons {
  border-radius: 6px;
  padding: 3px;
  box-shadow: inset 0 0 0 1px #006ce7;
}

.tox:not([dir="rtl"]) .tox-checkbox__label {
  margin-left: 4px;
}

.tox:not([dir="rtl"]) .tox-checkbox__input {
  left: -10000px;
}

.tox:not([dir="rtl"]) .tox-bar .tox-checkbox {
  margin-left: 4px;
}

.tox[dir="rtl"] .tox-checkbox__label {
  margin-right: 4px;
}

.tox[dir="rtl"] .tox-checkbox__input {
  right: -10000px;
}

.tox[dir="rtl"] .tox-bar .tox-checkbox {
  margin-right: 4px;
}

.tox .tox-collection--toolbar .tox-collection__group {
  padding: 0;
  display: flex;
}

.tox .tox-collection--grid .tox-collection__group {
  flex-wrap: wrap;
  max-height: 208px;
  padding: 0;
  display: flex;
  overflow: hidden auto;
}

.tox .tox-collection--list .tox-collection__group {
  border: 0 solid #e3e3e3;
  border-top-width: 1px;
  padding: 4px 0;
}

.tox .tox-collection--list .tox-collection__group:first-child {
  border-top-width: 0;
}

.tox .tox-collection__group-heading {
  color: #222f3eb3;
  cursor: default;
  text-transform: none;
  -webkit-touch-callout: none;
  user-select: none;
  background-color: #fcfcfc;
  margin-top: -4px;
  margin-bottom: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.tox .tox-collection__item {
  color: #222f3e;
  -webkit-touch-callout: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  display: flex;
}

.tox .tox-collection--list .tox-collection__item {
  padding: 4px 8px;
}

.tox .tox-collection--toolbar .tox-collection__item, .tox .tox-collection--grid .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}

.tox .tox-collection--list .tox-collection__item--enabled {
  color: #222f3e;
  background-color: #fff;
}

.tox .tox-collection--list .tox-collection__item--active {
  background-color: #cce2fa;
}

.tox .tox-collection--toolbar .tox-collection__item--enabled {
  color: #222f3e;
  background-color: #a6ccf7;
}

.tox .tox-collection--toolbar .tox-collection__item--active {
  background-color: #cce2fa;
}

.tox .tox-collection--grid .tox-collection__item--enabled {
  color: #222f3e;
  background-color: #a6ccf7;
}

.tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: #222f3e;
  background-color: #cce2fa;
}

.tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled), .tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: #222f3e;
}

.tox .tox-collection__item-checkmark, .tox .tox-collection__item-icon {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.tox .tox-collection__item-checkmark svg, .tox .tox-collection__item-icon svg {
  fill: currentColor;
}

.tox .tox-collection--toolbar-lg .tox-collection__item-icon {
  width: 48px;
  height: 48px;
}

.tox .tox-collection__item-label {
  color: currentColor;
  text-transform: none;
  word-break: break-all;
  flex: 1;
  max-width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

.tox .tox-collection__item-accessory {
  color: #222f3eb3;
  text-transform: none;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
}

.tox .tox-collection__item-caret {
  align-items: center;
  min-height: 24px;
  display: flex;
}

.tox .tox-collection__item-caret:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}

.tox .tox-collection__item-caret svg {
  fill: #222f3e;
}

.tox .tox-collection__item--state-disabled {
  color: #222f3e80;
  cursor: not-allowed;
  background-color: #0000;
}

.tox .tox-collection__item--state-disabled .tox-collection__item-caret svg {
  fill: #222f3e80;
}

.tox .tox-collection--list .tox-collection__item:not(.tox-collection__item--enabled) .tox-collection__item-checkmark svg, .tox .tox-collection--list .tox-collection__item:not(.tox-collection__item--enabled) .tox-collection__item-accessory + .tox-collection__item-checkmark {
  display: none;
}

.tox .tox-collection--horizontal {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  flex-wrap: nowrap;
  flex: none;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
  box-shadow: 0 0 2px #222f3e33, 0 4px 8px #222f3e26;
}

.tox .tox-collection--horizontal .tox-collection__group {
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 0 4px;
  display: flex;
}

.tox .tox-collection--horizontal .tox-collection__item {
  height: 28px;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
}

.tox .tox-collection--horizontal .tox-collection__item-label {
  white-space: nowrap;
}

.tox .tox-collection--horizontal .tox-collection__item-caret {
  margin-left: 4px;
}

.tox .tox-collection__item-container {
  display: flex;
}

.tox .tox-collection__item-container--row {
  flex-direction: row;
  flex: auto;
  align-items: center;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--align-left {
  margin-right: auto;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--align-right {
  justify-content: flex-end;
  margin-left: auto;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--valign-top {
  align-items: flex-start;
  margin-bottom: auto;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--valign-middle {
  align-items: center;
}

.tox .tox-collection__item-container--row.tox-collection__item-container--valign-bottom {
  align-items: flex-end;
  margin-top: auto;
}

.tox .tox-collection__item-container--column {
  flex-direction: column;
  flex: auto;
  align-self: center;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--align-left {
  align-items: flex-start;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--align-right {
  align-items: flex-end;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--valign-top {
  align-self: flex-start;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--valign-middle {
  align-self: center;
}

.tox .tox-collection__item-container--column.tox-collection__item-container--valign-bottom {
  align-self: flex-end;
}

.tox:not([dir="rtl"]) .tox-collection--horizontal .tox-collection__group:not(:last-of-type) {
  border-right: 1px solid #0000;
}

.tox:not([dir="rtl"]) .tox-collection--list .tox-collection__item > :not(:first-child) {
  margin-left: 8px;
}

.tox:not([dir="rtl"]) .tox-collection--list .tox-collection__item > .tox-collection__item-label:first-child {
  margin-left: 4px;
}

.tox:not([dir="rtl"]) .tox-collection__item-accessory {
  text-align: right;
  margin-left: 16px;
}

.tox:not([dir="rtl"]) .tox-collection .tox-collection__item-caret {
  margin-left: 16px;
}

.tox[dir="rtl"] .tox-collection--horizontal .tox-collection__group:not(:last-of-type) {
  border-left: 1px solid #0000;
}

.tox[dir="rtl"] .tox-collection--list .tox-collection__item > :not(:first-child) {
  margin-right: 8px;
}

.tox[dir="rtl"] .tox-collection--list .tox-collection__item > .tox-collection__item-label:first-child {
  margin-right: 4px;
}

.tox[dir="rtl"] .tox-collection__item-accessory {
  text-align: left;
  margin-right: 16px;
}

.tox[dir="rtl"] .tox-collection .tox-collection__item-caret {
  margin-right: 16px;
  transform: rotateY(180deg);
}

.tox[dir="rtl"] .tox-collection--horizontal .tox-collection__item-caret {
  margin-right: 4px;
}

.tox .tox-color-picker-container {
  flex-direction: row;
  height: 225px;
  margin: 0;
  display: flex;
}

.tox .tox-sv-palette {
  box-sizing: border-box;
  height: 100%;
  display: flex;
}

.tox .tox-sv-palette-spectrum {
  height: 100%;
}

.tox .tox-sv-palette, .tox .tox-sv-palette-spectrum {
  width: 225px;
}

.tox .tox-sv-palette-thumb {
  box-sizing: content-box;
  background: none;
  border: 1px solid #000;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
}

.tox .tox-sv-palette-inner-thumb {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
}

.tox .tox-hue-slider {
  box-sizing: border-box;
  width: 25px;
  height: 100%;
}

.tox .tox-hue-slider-spectrum {
  background: linear-gradient(red, #ff0080, #f0f, #8000ff, #00f, #0080ff, #0ff, #00ff80, #0f0, #80ff00, #ff0, #ff8000, red);
  width: 100%;
  height: 100%;
}

.tox .tox-hue-slider, .tox .tox-hue-slider-spectrum {
  width: 20px;
}

.tox .tox-hue-slider-spectrum:focus, .tox .tox-sv-palette-spectrum:focus {
  outline: solid #08f;
}

.tox .tox-hue-slider-thumb {
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #000;
  width: 100%;
  height: 4px;
}

.tox .tox-rgb-form {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.tox .tox-rgb-form div {
  width: inherit;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.tox .tox-rgb-form input {
  width: 6em;
}

.tox .tox-rgb-form input.tox-invalid {
  border: 1px solid red !important;
}

.tox .tox-rgb-form .tox-rgba-preview {
  border: 1px solid #000;
  flex-grow: 2;
  margin-bottom: 0;
}

.tox:not([dir="rtl"]) .tox-sv-palette, .tox:not([dir="rtl"]) .tox-hue-slider {
  margin-right: 15px;
}

.tox:not([dir="rtl"]) .tox-hue-slider-thumb {
  margin-left: -1px;
}

.tox:not([dir="rtl"]) .tox-rgb-form label {
  margin-right: .5em;
}

.tox[dir="rtl"] .tox-sv-palette, .tox[dir="rtl"] .tox-hue-slider {
  margin-left: 15px;
}

.tox[dir="rtl"] .tox-hue-slider-thumb {
  margin-right: -1px;
}

.tox[dir="rtl"] .tox-rgb-form label {
  margin-left: .5em;
}

.tox .tox-toolbar .tox-swatches, .tox .tox-toolbar__overflow .tox-swatches, .tox .tox-toolbar__primary .tox-swatches {
  margin: 5px 0 6px 11px;
}

.tox .tox-collection--list .tox-collection__group .tox-swatches-menu {
  border: 0;
  margin: -4px;
}

.tox .tox-swatches__row {
  display: flex;
}

.tox .tox-swatch {
  width: 30px;
  height: 30px;
  transition: transform .15s, box-shadow .15s;
}

.tox .tox-swatch:focus, .tox .tox-swatch:hover {
  transform: scale(.8);
  box-shadow: inset 0 0 0 1px #7f7f7f4d;
}

.tox .tox-swatch--remove {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tox .tox-swatch--remove svg path {
  stroke: #e74c3c;
}

.tox .tox-swatches__picker-btn {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
}

.tox .tox-swatches__picker-btn svg {
  fill: #222f3e;
  width: 24px;
  height: 24px;
}

.tox .tox-swatches__picker-btn:hover {
  background: #cce2fa;
}

.tox div.tox-swatch:not(.tox-swatch--remove) svg {
  fill: #222f3e;
  width: 24px;
  height: 24px;
  margin: 3px;
  display: none;
}

.tox div.tox-swatch:not(.tox-swatch--remove) svg path {
  fill: #fff;
  paint-order: stroke;
  stroke: #222f3e;
  stroke-width: 2px;
}

.tox div.tox-swatch:not(.tox-swatch--remove).tox-collection__item--enabled svg {
  display: block;
}

.tox:not([dir="rtl"]) .tox-swatches__picker-btn {
  margin-left: auto;
}

.tox[dir="rtl"] .tox-swatches__picker-btn {
  margin-right: auto;
}

.tox .tox-comment-thread {
  background: #fff;
  position: relative;
}

.tox .tox-comment-thread > :not(:first-child) {
  margin-top: 8px;
}

.tox .tox-comment {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 8px 8px 16px;
  position: relative;
  box-shadow: 0 4px 8px #222f3e1a;
}

.tox .tox-comment__header {
  color: #222f3e;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.tox .tox-comment__date {
  color: #222f3e;
  font-size: 12px;
  line-height: 18px;
}

.tox .tox-comment__body {
  color: #222f3e;
  text-transform: initial;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  position: relative;
}

.tox .tox-comment__body textarea {
  resize: none;
  white-space: normal;
  width: 100%;
}

.tox .tox-comment__expander {
  padding-top: 8px;
}

.tox .tox-comment__expander p {
  color: #222f3eb3;
  font-size: 14px;
  font-style: normal;
}

.tox .tox-comment__body p {
  margin: 0;
}

.tox .tox-comment__buttonspacing {
  text-align: center;
  padding-top: 16px;
}

.tox .tox-comment-thread__overlay:after {
  content: "";
  opacity: .9;
  z-index: 5;
  background: #fff;
  display: flex;
  position: absolute;
  inset: 0;
}

.tox .tox-comment__reply {
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-top: 8px;
  display: flex;
}

.tox .tox-comment__reply > :first-child {
  width: 100%;
  margin-bottom: 8px;
}

.tox .tox-comment__edit {
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 16px;
  display: flex;
}

.tox .tox-comment__gradient:after {
  content: "";
  background: linear-gradient(#fff0, #fff);
  width: 100%;
  height: 5em;
  margin-top: -40px;
  display: block;
  position: absolute;
  bottom: 0;
}

.tox .tox-comment__overlay {
  opacity: .9;
  text-align: center;
  z-index: 5;
  background: #fff;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: absolute;
  inset: 0;
}

.tox .tox-comment__loading-text {
  color: #222f3e;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.tox .tox-comment__loading-text > div {
  padding-bottom: 16px;
}

.tox .tox-comment__overlaytext {
  z-index: 10;
  flex-direction: column;
  padding: 1em;
  font-size: 14px;
  position: absolute;
  inset: 0;
}

.tox .tox-comment__overlaytext p {
  color: #222f3e;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 8px 8px #fff;
}

.tox .tox-comment__overlaytext div:nth-of-type(2) {
  font-size: .8em;
}

.tox .tox-comment__busy-spinner {
  z-index: 20;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.tox .tox-comment__scroll {
  flex-direction: column;
  flex-shrink: 1;
  display: flex;
  overflow: auto;
}

.tox .tox-conversations {
  margin: 8px;
}

.tox:not([dir="rtl"]) .tox-comment__edit, .tox:not([dir="rtl"]) .tox-comment__buttonspacing > :last-child, .tox:not([dir="rtl"]) .tox-comment__edit > :last-child, .tox:not([dir="rtl"]) .tox-comment__reply > :last-child {
  margin-left: 8px;
}

.tox[dir="rtl"] .tox-comment__edit, .tox[dir="rtl"] .tox-comment__buttonspacing > :last-child, .tox[dir="rtl"] .tox-comment__edit > :last-child, .tox[dir="rtl"] .tox-comment__reply > :last-child {
  margin-right: 8px;
}

.tox .tox-user {
  align-items: center;
  display: flex;
}

.tox .tox-user__avatar svg {
  fill: #222f3eb3;
}

.tox .tox-user__avatar img {
  object-fit: cover;
  vertical-align: middle;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.tox .tox-user__name {
  color: #222f3e;
  text-transform: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.tox:not([dir="rtl"]) .tox-user__avatar img, .tox:not([dir="rtl"]) .tox-user__avatar svg {
  margin-right: 8px;
}

.tox:not([dir="rtl"]) .tox-user__avatar + .tox-user__name, .tox[dir="rtl"] .tox-user__avatar img, .tox[dir="rtl"] .tox-user__avatar svg {
  margin-left: 8px;
}

.tox[dir="rtl"] .tox-user__avatar + .tox-user__name {
  margin-right: 8px;
}

.tox .tox-dialog-wrap {
  z-index: 1100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.tox .tox-dialog-wrap__backdrop {
  z-index: 1;
  background-color: #ffffffbf;
  position: absolute;
  inset: 0;
}

.tox .tox-dialog-wrap__backdrop--opaque {
  background-color: #fff;
}

.tox .tox-dialog {
  z-index: 2;
  background-color: #fff;
  border: 0 solid #eee;
  border-radius: 10px;
  flex-direction: column;
  width: 95vw;
  max-width: 480px;
  max-height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 16px 16px -10px #222f3e26, 0 0 40px 1px #222f3e26;
}

@media only screen and (width <= 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog {
    align-self: flex-start;
    width: calc(100vw - 16px);
    max-height: calc(100vh - 16px);
    margin: 8px auto;
  }
}

.tox .tox-dialog-inline {
  z-index: 1100;
}

.tox .tox-dialog__header {
  color: #222f3e;
  background-color: #fff;
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 0;
  font-size: 16px;
  display: flex;
  position: relative;
}

.tox .tox-dialog__header .tox-button {
  z-index: 1;
}

.tox .tox-dialog__draghandle {
  cursor: grab;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tox .tox-dialog__draghandle:active {
  cursor: grabbing;
}

.tox .tox-dialog__dismiss {
  margin-left: auto;
}

.tox .tox-dialog__title {
  text-transform: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

.tox .tox-dialog__body {
  color: #222f3e;
  text-align: left;
  text-transform: none;
  flex: 1;
  min-width: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  display: flex;
}

@media only screen and (width <= 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body {
    flex-direction: column;
  }
}

.tox .tox-dialog__body-nav {
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  padding: 16px;
  display: flex;
}

@media only screen and (width >= 768px) {
  .tox .tox-dialog__body-nav {
    max-width: 11em;
  }
}

@media only screen and (width <= 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body-nav {
    -webkit-overflow-scrolling: touch;
    flex-direction: row;
    padding-bottom: 0;
    overflow-x: auto;
  }
}

.tox .tox-dialog__body-nav-item {
  color: #222f3eb3;
  border-bottom: 2px solid #0000;
  flex-shrink: 0;
  max-width: 13em;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.3;
  text-decoration: none;
  display: inline-block;
}

.tox .tox-dialog__body-nav-item:focus {
  background-color: #006ce71a;
}

.tox .tox-dialog__body-nav-item--active {
  color: #006ce7;
  border-bottom: 2px solid #006ce7;
}

.tox .tox-dialog__body-content {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  flex: 1;
  max-height: min(650px, 100vh - 110px);
  padding: 16px;
  display: flex;
  overflow: auto;
}

.tox .tox-dialog__body-content > * {
  margin-top: 16px;
  margin-bottom: 0;
}

.tox .tox-dialog__body-content > :first-child {
  margin-top: 0;
}

.tox .tox-dialog__body-content > :last-child {
  margin-bottom: 0;
}

.tox .tox-dialog__body-content > :only-child {
  margin-top: 0;
  margin-bottom: 0;
}

.tox .tox-dialog__body-content a {
  color: #006ce7;
  cursor: pointer;
  text-decoration: underline;
}

.tox .tox-dialog__body-content a:focus, .tox .tox-dialog__body-content a:hover {
  color: #003c81;
  text-decoration: underline;
}

.tox .tox-dialog__body-content a:focus-visible {
  outline-offset: 2px;
  border-radius: 1px;
  outline: 2px solid #006ce7;
}

.tox .tox-dialog__body-content a:active {
  color: #00244e;
  text-decoration: underline;
}

.tox .tox-dialog__body-content svg {
  fill: #222f3e;
}

.tox .tox-dialog__body-content strong {
  font-weight: 700;
}

.tox .tox-dialog__body-content ul {
  list-style-type: disc;
}

.tox .tox-dialog__body-content dd, .tox .tox-dialog__body-content ol, .tox .tox-dialog__body-content ul {
  padding-inline-start: 2.5rem;
}

.tox .tox-dialog__body-content dl, .tox .tox-dialog__body-content ol, .tox .tox-dialog__body-content ul {
  margin-bottom: 16px;
}

.tox .tox-dialog__body-content dd, .tox .tox-dialog__body-content dl, .tox .tox-dialog__body-content dt, .tox .tox-dialog__body-content ol, .tox .tox-dialog__body-content ul {
  margin-inline: 0;
  display: block;
}

.tox .tox-dialog__body-content .tox-form__group h1 {
  color: #222f3e;
  letter-spacing: normal;
  text-transform: none;
  margin-top: 2rem;
  margin-bottom: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.tox .tox-dialog__body-content .tox-form__group h2 {
  color: #222f3e;
  letter-spacing: normal;
  text-transform: none;
  margin-top: 2rem;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.tox .tox-dialog__body-content .tox-form__group p {
  margin-bottom: 16px;
}

.tox .tox-dialog__body-content .tox-form__group h1:first-child, .tox .tox-dialog__body-content .tox-form__group h2:first-child, .tox .tox-dialog__body-content .tox-form__group p:first-child {
  margin-top: 0;
}

.tox .tox-dialog__body-content .tox-form__group h1:last-child, .tox .tox-dialog__body-content .tox-form__group h2:last-child, .tox .tox-dialog__body-content .tox-form__group p:last-child {
  margin-bottom: 0;
}

.tox .tox-dialog__body-content .tox-form__group h1:only-child, .tox .tox-dialog__body-content .tox-form__group h2:only-child, .tox .tox-dialog__body-content .tox-form__group p:only-child {
  margin-top: 0;
  margin-bottom: 0;
}

.tox .tox-dialog__body-content .tox-form__group .tox-label.tox-label--center {
  text-align: center;
}

.tox .tox-dialog__body-content .tox-form__group .tox-label.tox-label--end {
  text-align: end;
}

.tox .tox-dialog--width-lg {
  max-width: 1200px;
  height: 650px;
}

.tox .tox-dialog--fullscreen {
  max-width: 100%;
  height: 100%;
}

.tox .tox-dialog--fullscreen .tox-dialog__body-content {
  max-height: 100%;
}

.tox .tox-dialog--width-md {
  max-width: 800px;
}

.tox .tox-dialog--width-md .tox-dialog__body-content {
  overflow: auto;
}

.tox .tox-dialog__body-content--centered {
  text-align: center;
}

.tox .tox-dialog__footer {
  background-color: #fff;
  border-top: none;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.tox .tox-dialog__footer-end, .tox .tox-dialog__footer-start {
  display: flex;
}

.tox .tox-dialog__busy-spinner {
  z-index: 3;
  background-color: #ffffffbf;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.tox .tox-dialog__table {
  border-collapse: collapse;
  width: 100%;
}

.tox .tox-dialog__table thead th {
  padding-bottom: 8px;
  font-weight: 700;
}

.tox .tox-dialog__table thead th:first-child {
  padding-right: 8px;
}

.tox .tox-dialog__table tbody tr {
  border-bottom: 1px solid #626262;
}

.tox .tox-dialog__table tbody tr:last-child {
  border-bottom: none;
}

.tox .tox-dialog__table td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.tox .tox-dialog__table td:first-child {
  padding-right: 8px;
}

.tox .tox-dialog__iframe {
  min-height: 200px;
}

.tox .tox-dialog__iframe.tox-dialog__iframe--opaque {
  background: #fff;
}

.tox .tox-navobj-bordered {
  position: relative;
}

.tox .tox-navobj-bordered:before {
  content: "";
  opacity: 1;
  pointer-events: none;
  z-index: 1;
  border: 1px solid #eee;
  border-radius: 6px;
  position: absolute;
  inset: 0;
}

.tox .tox-navobj-bordered-focus.tox-navobj-bordered:before {
  border-color: #006ce7;
  outline: 0;
  box-shadow: 0 0 0 2px #006ce740;
}

.tox .tox-dialog__popups {
  z-index: 1100;
  width: 100%;
  position: absolute;
}

.tox .tox-dialog__body-iframe {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.tox .tox-dialog__body-iframe .tox-navobj {
  flex: 1;
  display: flex;
}

.tox .tox-dialog__body-iframe .tox-navobj :nth-child(2) {
  flex: 1;
  height: 100%;
}

.tox .tox-dialog-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}

.tox .tox-dialog-dock-fadein {
  opacity: 1;
  visibility: visible;
}

.tox .tox-dialog-dock-transition {
  transition: visibility 0s linear .3s, opacity .3s;
}

.tox .tox-dialog-dock-transition.tox-dialog-dock-fadein {
  transition-delay: 0s;
}

@media only screen and (width <= 767px) {
  body:not(.tox-force-desktop) .tox:not([dir="rtl"]) .tox-dialog__body-nav {
    margin-right: 0;
  }

  body:not(.tox-force-desktop) .tox:not([dir="rtl"]) .tox-dialog__body-nav-item:not(:first-child) {
    margin-left: 8px;
  }
}

.tox:not([dir="rtl"]) .tox-dialog__footer .tox-dialog__footer-end > *, .tox:not([dir="rtl"]) .tox-dialog__footer .tox-dialog__footer-start > * {
  margin-left: 8px;
}

.tox[dir="rtl"] .tox-dialog__body {
  text-align: right;
}

@media only screen and (width <= 767px) {
  body:not(.tox-force-desktop) .tox[dir="rtl"] .tox-dialog__body-nav {
    margin-left: 0;
  }

  body:not(.tox-force-desktop) .tox[dir="rtl"] .tox-dialog__body-nav-item:not(:first-child) {
    margin-right: 8px;
  }
}

.tox[dir="rtl"] .tox-dialog__footer .tox-dialog__footer-end > *, .tox[dir="rtl"] .tox-dialog__footer .tox-dialog__footer-start > * {
  margin-right: 8px;
}

body.tox-dialog__disable-scroll {
  overflow: hidden;
}

.tox .tox-dropzone-container {
  flex: 1;
  display: flex;
}

.tox .tox-dropzone {
  box-sizing: border-box;
  background: #fff;
  border: 2px dashed #eee;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: 10px;
  display: flex;
}

.tox .tox-dropzone p {
  color: #222f3eb3;
  margin: 0 0 16px;
}

.tox .tox-edit-area {
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tox .tox-edit-area:before {
  content: "";
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  border: 2px solid #2d6adf;
  border-radius: 4px;
  transition: opacity .15s;
  position: absolute;
  inset: 0;
}

.tox .tox-edit-area__iframe {
  box-sizing: border-box;
  background-color: #fff;
  border: 0;
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.tox.tox-edit-focus .tox-edit-area:before {
  opacity: 1;
}

.tox.tox-inline-edit-area {
  border: 1px dotted #eee;
}

.tox .tox-editor-container {
  flex-direction: column;
  flex: auto;
  display: flex;
  overflow: hidden;
}

.tox .tox-editor-header {
  z-index: 2;
  grid-template-columns: 1fr min-content;
  display: grid;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #fff;
  border-bottom: none;
  padding: 4px 0;
  box-shadow: 0 2px 2px -2px #222f3e1a, 0 8px 8px -4px #222f3e12;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header:not(.tox-editor-dock-transition) {
  transition: box-shadow .5s;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-bottom .tox-editor-header {
  box-shadow: none;
  border-top: 1px solid #e3e3e3;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-sticky-on .tox-editor-header {
  background-color: #fff;
  padding: 4px 0;
  box-shadow: 0 2px 2px -2px #222f3e33, 0 8px 8px -4px #222f3e26;
}

.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-sticky-on.tox-tinymce--toolbar-bottom .tox-editor-header {
  box-shadow: 0 2px 2px -2px #222f3e33, 0 8px 8px -4px #222f3e26;
}

.tox.tox:not(.tox-tinymce-inline) .tox-editor-header.tox-editor-header--empty {
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
}

.tox-editor-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}

.tox-editor-dock-fadein {
  opacity: 1;
  visibility: visible;
}

.tox-editor-dock-transition {
  transition: visibility 0s linear .25s, opacity .25s;
}

.tox-editor-dock-transition.tox-editor-dock-fadein {
  transition-delay: 0s;
}

.tox .tox-control-wrap {
  flex: 1;
  position: relative;
}

.tox .tox-control-wrap:not(.tox-control-wrap--status-invalid) .tox-control-wrap__status-icon-invalid, .tox .tox-control-wrap:not(.tox-control-wrap--status-unknown) .tox-control-wrap__status-icon-unknown, .tox .tox-control-wrap:not(.tox-control-wrap--status-valid) .tox-control-wrap__status-icon-valid {
  display: none;
}

.tox .tox-control-wrap svg {
  display: block;
}

.tox .tox-control-wrap__status-icon-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tox .tox-control-wrap__status-icon-invalid svg {
  fill: #c00;
}

.tox .tox-control-wrap__status-icon-unknown svg {
  fill: orange;
}

.tox .tox-control-wrap__status-icon-valid svg {
  fill: green;
}

.tox:not([dir="rtl"]) .tox-control-wrap--status-invalid .tox-textfield, .tox:not([dir="rtl"]) .tox-control-wrap--status-unknown .tox-textfield, .tox:not([dir="rtl"]) .tox-control-wrap--status-valid .tox-textfield {
  padding-right: 32px;
}

.tox:not([dir="rtl"]) .tox-control-wrap__status-icon-wrap {
  right: 4px;
}

.tox[dir="rtl"] .tox-control-wrap--status-invalid .tox-textfield, .tox[dir="rtl"] .tox-control-wrap--status-unknown .tox-textfield, .tox[dir="rtl"] .tox-control-wrap--status-valid .tox-textfield {
  padding-left: 32px;
}

.tox[dir="rtl"] .tox-control-wrap__status-icon-wrap {
  left: 4px;
}

.tox .tox-autocompleter {
  max-width: 25em;
}

.tox .tox-autocompleter .tox-menu {
  box-sizing: border-box;
  max-width: 25em;
}

.tox .tox-autocompleter .tox-autocompleter-highlight {
  font-weight: 700;
}

.tox .tox-color-input {
  z-index: 1;
  display: flex;
  position: relative;
}

.tox .tox-color-input .tox-textfield {
  z-index: -1;
}

.tox .tox-color-input span {
  box-shadow: none;
  box-sizing: border-box;
  border: 1px solid #222f3e33;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 6px;
}

.tox .tox-color-input span:focus:not([aria-disabled="true"]), .tox .tox-color-input span:hover:not([aria-disabled="true"]) {
  cursor: pointer;
  border-color: #006ce7;
}

.tox .tox-color-input span:before {
  box-sizing: border-box;
  content: "";
  z-index: -1;
  background-image: linear-gradient(45deg, #00000040 25%, #0000 25%), linear-gradient(-45deg, #00000040 25%, #0000 25%), linear-gradient(45deg, #0000 75%, #00000040 75%), linear-gradient(-45deg, #0000 75%, #00000040 75%);
  background-position: 0 0, 0 6px, 6px -6px, -6px 0;
  background-size: 12px 12px;
  border: 1px solid #fff;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.tox .tox-color-input span[aria-disabled="true"] {
  cursor: not-allowed;
}

.tox:not([dir="rtl"]) .tox-color-input .tox-textfield {
  padding-left: 36px;
}

.tox:not([dir="rtl"]) .tox-color-input span {
  left: 6px;
}

.tox[dir="rtl"] .tox-color-input .tox-textfield {
  padding-right: 36px;
}

.tox[dir="rtl"] .tox-color-input span {
  right: 6px;
}

.tox .tox-label, .tox .tox-toolbar-label {
  color: #222f3eb3;
  text-transform: none;
  white-space: nowrap;
  padding: 0 8px 0 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  display: block;
}

.tox .tox-toolbar-label {
  padding: 0 8px;
}

.tox[dir="rtl"] .tox-label {
  padding: 0 0 0 8px;
}

.tox .tox-form {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.tox .tox-form__group {
  box-sizing: border-box;
  margin-bottom: 4px;
}

.tox .tox-form-group--maximize {
  flex: 1;
}

.tox .tox-form__group--error {
  color: #c00;
}

.tox .tox-form__group--collection {
  display: flex;
}

.tox .tox-form__grid {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.tox .tox-form__grid--2col > .tox-form__group {
  width: calc(50% - 4px);
}

.tox .tox-form__grid--3col > .tox-form__group {
  width: calc(33.3333% - 4px);
}

.tox .tox-form__grid--4col > .tox-form__group {
  width: calc(25% - 4px);
}

.tox .tox-form__controls-h-stack, .tox .tox-form__group--inline {
  align-items: center;
  display: flex;
}

.tox .tox-form__group--stretched {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.tox .tox-form__group--stretched .tox-textarea {
  flex: 1;
}

.tox .tox-form__group--stretched .tox-navobj {
  flex: 1;
  display: flex;
}

.tox .tox-form__group--stretched .tox-navobj :nth-child(2) {
  flex: 1;
  height: 100%;
}

.tox:not([dir="rtl"]) .tox-form__controls-h-stack > :not(:first-child) {
  margin-left: 4px;
}

.tox[dir="rtl"] .tox-form__controls-h-stack > :not(:first-child) {
  margin-right: 4px;
}

.tox .tox-lock.tox-locked .tox-lock-icon__unlock, .tox .tox-lock:not(.tox-locked) .tox-lock-icon__lock {
  display: none;
}

.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textarea-wrap .tox-textarea:focus, .tox .tox-textfield, .tox .tox-toolbar-textfield {
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #222f3e;
  resize: none;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: 0;
  width: 100%;
  min-height: 34px;
  margin: 0;
  padding: 5px 5.5px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.tox .tox-textarea[disabled], .tox .tox-textfield[disabled] {
  color: #222f3ed9;
  cursor: not-allowed;
  background-color: #f2f2f2;
}

.tox .tox-custom-editor:focus-within, .tox .tox-listboxfield .tox-listbox--select:focus, .tox .tox-textarea-wrap:focus-within, .tox .tox-textarea:focus, .tox .tox-textfield:focus {
  background-color: #fff;
  border-color: #006ce7;
  outline: 0;
  box-shadow: 0 0 0 2px #006ce740;
}

.tox .tox-toolbar-textfield {
  border-width: 0;
  max-width: 250px;
  margin-top: 2px;
  margin-bottom: 3px;
}

.tox .tox-naked-btn {
  box-shadow: unset;
  color: #006ce7;
  cursor: pointer;
  background-color: #0000;
  border: 0 #0000;
  margin: 0;
  padding: 0;
  display: block;
}

.tox .tox-naked-btn svg {
  fill: #222f3e;
  display: block;
}

.tox:not([dir="rtl"]) .tox-toolbar-textfield + * {
  margin-left: 4px;
}

.tox[dir="rtl"] .tox-toolbar-textfield + * {
  margin-right: 4px;
}

.tox .tox-listboxfield {
  cursor: pointer;
  position: relative;
}

.tox .tox-listboxfield .tox-listbox--select[disabled] {
  color: #222f3ed9;
  cursor: not-allowed;
  background-color: #f2f2f2;
}

.tox .tox-listbox__select-label {
  cursor: default;
  flex: 1;
  margin: 0 4px;
}

.tox .tox-listbox__select-chevron {
  justify-content: center;
  align-items: center;
  width: 16px;
  display: flex;
}

.tox .tox-listbox__select-chevron svg {
  fill: #222f3e;
}

.tox .tox-listboxfield .tox-listbox--select {
  align-items: center;
  display: flex;
}

.tox:not([dir="rtl"]) .tox-listboxfield svg {
  right: 8px;
}

.tox[dir="rtl"] .tox-listboxfield svg {
  left: 8px;
}

.tox .tox-selectfield {
  cursor: pointer;
  position: relative;
}

.tox .tox-selectfield select {
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #222f3e;
  resize: none;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: 0;
  width: 100%;
  min-height: 34px;
  margin: 0;
  padding: 5px 5.5px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.tox .tox-selectfield select[disabled] {
  color: #222f3ed9;
  cursor: not-allowed;
  background-color: #f2f2f2;
}

.tox .tox-selectfield select::-ms-expand {
  display: none;
}

.tox .tox-selectfield select:focus {
  background-color: #fff;
  border-color: #006ce7;
  outline: 0;
  box-shadow: 0 0 0 2px #006ce740;
}

.tox .tox-selectfield svg {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tox:not([dir="rtl"]) .tox-selectfield select[size="0"], .tox:not([dir="rtl"]) .tox-selectfield select[size="1"] {
  padding-right: 24px;
}

.tox:not([dir="rtl"]) .tox-selectfield svg {
  right: 8px;
}

.tox[dir="rtl"] .tox-selectfield select[size="0"], .tox[dir="rtl"] .tox-selectfield select[size="1"] {
  padding-left: 24px;
}

.tox[dir="rtl"] .tox-selectfield svg {
  left: 8px;
}

.tox .tox-textarea-wrap {
  border: 1px solid #eee;
  border-radius: 6px;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.tox .tox-textarea {
  appearance: textarea;
  white-space: pre-wrap;
}

.tox .tox-textarea-wrap .tox-textarea, .tox .tox-textarea-wrap .tox-textarea:focus {
  border: none;
}

.tox-fullscreen {
  overscroll-behavior: none;
  touch-action: pinch-zoom;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.tox.tox-tinymce.tox-fullscreen .tox-statusbar__resize-handle {
  display: none;
}

.tox-shadowhost.tox-fullscreen, .tox.tox-tinymce.tox-fullscreen {
  z-index: 1200;
  position: fixed;
  top: 0;
  left: 0;
}

.tox.tox-tinymce.tox-fullscreen {
  background-color: #0000;
}

.tox-fullscreen .tox.tox-tinymce-aux, .tox-fullscreen ~ .tox.tox-tinymce-aux {
  z-index: 1201;
}

.tox .tox-help__more-link {
  margin-top: 1em;
  list-style: none;
}

.tox .tox-imagepreview {
  background-color: #666;
  width: 100%;
  height: 380px;
  position: relative;
  overflow: hidden;
}

.tox .tox-imagepreview.tox-imagepreview__loaded {
  overflow: auto;
}

.tox .tox-imagepreview__container {
  display: flex;
  position: absolute;
  top: 100vw;
  left: 100vw;
}

.tox .tox-imagepreview__image {
  background: url("data:image/gif;base64,R0lGODdhDAAMAIABAMzMzP///ywAAAAADAAMAAACFoQfqYeabNyDMkBQb81Uat85nxguUAEAOw==");
}

.tox .tox-image-tools .tox-spacer {
  flex: 1;
}

.tox .tox-image-tools .tox-bar {
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
}

.tox .tox-image-tools .tox-imagepreview, .tox .tox-image-tools .tox-imagepreview + .tox-bar {
  margin-top: 8px;
}

.tox .tox-image-tools .tox-croprect-block {
  opacity: .5;
  zoom: 1;
  background: #000;
  position: absolute;
}

.tox .tox-image-tools .tox-croprect-handle {
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.tox .tox-image-tools .tox-croprect-handle-move {
  cursor: move;
  border: 0;
  position: absolute;
}

.tox .tox-image-tools .tox-croprect-handle-nw {
  cursor: nw-resize;
  border-width: 2px 0 0 2px;
  margin: -2px 0 0 -2px;
  top: 100px;
  left: 100px;
}

.tox .tox-image-tools .tox-croprect-handle-ne {
  cursor: ne-resize;
  border-width: 2px 2px 0 0;
  margin: -2px 0 0 -20px;
  top: 100px;
  left: 200px;
}

.tox .tox-image-tools .tox-croprect-handle-sw {
  cursor: sw-resize;
  border-width: 0 0 2px 2px;
  margin: -20px 2px 0 -2px;
  top: 200px;
  left: 100px;
}

.tox .tox-image-tools .tox-croprect-handle-se {
  cursor: se-resize;
  border-width: 0 2px 2px 0;
  margin: -20px 0 0 -20px;
  top: 200px;
  left: 200px;
}

.tox .tox-insert-table-picker {
  flex-wrap: wrap;
  width: 170px;
  display: flex;
}

.tox .tox-insert-table-picker > div {
  box-sizing: border-box;
  border: 1px solid #eee;
  border-width: 0 1px 1px 0;
  width: 17px;
  height: 17px;
}

.tox .tox-collection--list .tox-collection__group .tox-insert-table-picker {
  margin: -4px;
}

.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
  background-color: #006ce780;
  border-color: #006ce780;
}

.tox .tox-insert-table-picker__label {
  color: #222f3eb3;
  text-align: center;
  width: 100%;
  padding: 4px;
  font-size: 14px;
  display: block;
}

.tox:not([dir="rtl"]) .tox-insert-table-picker > div:nth-child(10n), .tox[dir="rtl"] .tox-insert-table-picker > div:nth-child(10n+1) {
  border-right: 0;
}

.tox .tox-menu {
  vertical-align: top;
  z-index: 1150;
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 6px;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0 0 2px #222f3e33, 0 4px 8px #222f3e26;
}

.tox .tox-menu.tox-collection.tox-collection--list {
  padding: 0 4px;
}

.tox .tox-menu.tox-collection.tox-collection--toolbar, .tox .tox-menu.tox-collection.tox-collection--grid {
  padding: 8px;
}

@media only screen and (width >= 768px) {
  .tox .tox-menu .tox-collection__item-label {
    overflow-wrap: break-word;
    word-break: normal;
  }

  .tox .tox-dialog__popups .tox-menu .tox-collection__item-label {
    word-break: break-all;
  }
}

.tox .tox-menu__label blockquote, .tox .tox-menu__label code, .tox .tox-menu__label h1, .tox .tox-menu__label h2, .tox .tox-menu__label h3, .tox .tox-menu__label h4, .tox .tox-menu__label h5, .tox .tox-menu__label h6, .tox .tox-menu__label p {
  margin: 0;
}

.tox .tox-menubar {
  background: #fff repeating-linear-gradient(#0000 0 1px, #0000 1px 39px) 50% 39px / 100% calc(100% - 39px) no-repeat;
  flex-wrap: wrap;
  flex: none;
  grid-area: 1 / 1 / auto / -1;
  padding: 0 11px 0 12px;
  display: flex;
}

.tox .tox-promotion + .tox-menubar {
  grid-column: 1;
}

.tox .tox-promotion {
  padding-inline: 4px 8px;
  background: #fff repeating-linear-gradient(#0000 0 1px, #0000 1px 39px) 50% 39px / 100% calc(100% - 39px) no-repeat;
  grid-area: 1 / 2;
  padding-top: 5px;
}

.tox .tox-promotion-link {
  color: #086be6;
  cursor: pointer;
  white-space: nowrap;
  background-color: #e8f1f8;
  border-radius: 5px;
  align-items: unsafe center;
  height: 26.6px;
  padding: 4px 8px;
  font-size: 14px;
  display: flex;
}

.tox .tox-promotion-link:hover {
  background-color: #b4d7ff;
}

.tox .tox-promotion-link:focus {
  background-color: #d9edf7;
}

.tox .tox-mbtn {
  box-shadow: none;
  color: #222f3e;
  text-transform: none;
  background: none;
  border: 0;
  border-radius: 3px;
  outline: 0;
  flex: none;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 28px;
  margin: 5px 1px 6px 0;
  padding: 0 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  overflow: hidden;
}

.tox .tox-mbtn[disabled] {
  box-shadow: none;
  color: #222f3e80;
  cursor: not-allowed;
  background-color: #0000;
  border: 0;
}

.tox .tox-mbtn:focus:not(:disabled) {
  box-shadow: none;
  color: #222f3e;
  background: #cce2fa;
  border: 0;
}

.tox .tox-mbtn--active {
  box-shadow: none;
  color: #222f3e;
  background: #a6ccf7;
  border: 0;
}

.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  box-shadow: none;
  color: #222f3e;
  background: #cce2fa;
  border: 0;
}

.tox .tox-mbtn__select-label {
  cursor: default;
  margin: 0 4px;
  font-weight: 400;
}

.tox .tox-mbtn[disabled] .tox-mbtn__select-label {
  cursor: not-allowed;
}

.tox .tox-mbtn__select-chevron {
  justify-content: center;
  align-items: center;
  width: 16px;
  display: none;
}

.tox .tox-notification {
  box-shadow: none;
  box-sizing: border-box;
  opacity: 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  grid-template-columns: minmax(40px, 1fr) auto minmax(40px, 1fr);
  margin-top: 4px;
  padding: 4px;
  font-size: 14px;
  font-weight: 400;
  transition: transform .1s ease-in, opacity .15s ease-in;
  display: grid;
}

.tox .tox-notification p {
  font-size: 14px;
  font-weight: 400;
}

.tox .tox-notification a {
  cursor: pointer;
  text-decoration: underline;
}

.tox .tox-notification--in {
  opacity: 1;
}

.tox .tox-notification--success {
  color: #222f3e;
  background-color: #e4eeda;
  border-color: #d7e6c8;
}

.tox .tox-notification--success p {
  color: #222f3e;
}

.tox .tox-notification--success a {
  color: #517342;
}

.tox .tox-notification--success svg {
  fill: #222f3e;
}

.tox .tox-notification--error {
  color: #222f3e;
  background-color: #f5cccc;
  border-color: #f0b3b3;
}

.tox .tox-notification--error p {
  color: #222f3e;
}

.tox .tox-notification--error a {
  color: #77181f;
}

.tox .tox-notification--error svg {
  fill: #222f3e;
}

.tox .tox-notification--warn, .tox .tox-notification--warning {
  color: #222f3e;
  background-color: #fff5cc;
  border-color: #fff0b3;
}

.tox .tox-notification--warn p, .tox .tox-notification--warning p {
  color: #222f3e;
}

.tox .tox-notification--warn a, .tox .tox-notification--warning a {
  color: #7a6e25;
}

.tox .tox-notification--warn svg, .tox .tox-notification--warning svg {
  fill: #222f3e;
}

.tox .tox-notification--info {
  color: #222f3e;
  background-color: #d6e7fb;
  border-color: #c1dbf9;
}

.tox .tox-notification--info p {
  color: #222f3e;
}

.tox .tox-notification--info a {
  color: #2a64a6;
}

.tox .tox-notification--info svg {
  fill: #222f3e;
}

.tox .tox-notification__body {
  color: #222f3e;
  text-align: center;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;
  grid-area: 1 / 2 / 2 / 3;
  align-self: center;
  font-size: 14px;
}

.tox .tox-notification__body > * {
  margin: 0;
}

.tox .tox-notification__body > * + * {
  margin-top: 1rem;
}

.tox .tox-notification__icon {
  grid-area: 1 / 1 / 2 / 2;
  place-self: center end;
}

.tox .tox-notification__icon svg {
  display: block;
}

.tox .tox-notification__dismiss {
  grid-area: 1 / 3 / 2 / 4;
  place-self: start end;
}

.tox .tox-notification .tox-progress-bar {
  grid-area: 2 / 1 / 3 / 4;
  justify-self: center;
}

.tox .tox-pop {
  display: inline-block;
  position: relative;
}

.tox .tox-pop--resizing {
  transition: width .1s;
}

.tox .tox-pop--resizing .tox-toolbar, .tox .tox-pop--resizing .tox-toolbar__group {
  flex-wrap: nowrap;
}

.tox .tox-pop--transition {
  transition: left .15s, right .15s, top .15s, bottom .15s;
}

.tox .tox-pop--transition:after, .tox .tox-pop--transition:before {
  transition: all .15s, visibility, opacity 75ms 75ms;
}

.tox .tox-pop__dialog {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  min-width: 0;
  overflow: hidden;
  box-shadow: 0 0 2px #222f3e33, 0 4px 8px #222f3e26;
}

.tox .tox-pop__dialog > :not(.tox-toolbar) {
  margin: 4px 4px 4px 8px;
}

.tox .tox-pop__dialog .tox-toolbar {
  background-color: #0000;
  margin-bottom: -1px;
}

.tox .tox-pop:after, .tox .tox-pop:before {
  content: "";
  opacity: 1;
  border-style: solid;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.tox .tox-pop.tox-pop--inset:after, .tox .tox-pop.tox-pop--inset:before {
  opacity: 0;
  transition: all 0s .15s, visibility, opacity 75ms;
}

.tox .tox-pop.tox-pop--bottom:after, .tox .tox-pop.tox-pop--bottom:before {
  top: 100%;
  left: 50%;
}

.tox .tox-pop.tox-pop--bottom:after {
  border-width: 8px;
  border-color: #fff #0000 #0000;
  margin-top: -1px;
  margin-left: -8px;
}

.tox .tox-pop.tox-pop--bottom:before {
  border-width: 9px;
  border-color: #eee #0000 #0000;
  margin-left: -9px;
}

.tox .tox-pop.tox-pop--top:after, .tox .tox-pop.tox-pop--top:before {
  top: 0;
  left: 50%;
  transform: translateY(-100%);
}

.tox .tox-pop.tox-pop--top:after {
  border-width: 8px;
  border-color: #0000 #0000 #fff;
  margin-top: 1px;
  margin-left: -8px;
}

.tox .tox-pop.tox-pop--top:before {
  border-width: 9px;
  border-color: #0000 #0000 #eee;
  margin-left: -9px;
}

.tox .tox-pop.tox-pop--left:after, .tox .tox-pop.tox-pop--left:before {
  top: calc(50% - 1px);
  left: 0;
  transform: translateY(-50%);
}

.tox .tox-pop.tox-pop--left:after {
  border-width: 8px;
  border-color: #0000 #fff #0000 #0000;
  margin-left: -15px;
}

.tox .tox-pop.tox-pop--left:before {
  border-width: 10px;
  border-color: #0000 #eee #0000 #0000;
  margin-left: -19px;
}

.tox .tox-pop.tox-pop--right:after, .tox .tox-pop.tox-pop--right:before {
  top: calc(50% + 1px);
  left: 100%;
  transform: translateY(-50%);
}

.tox .tox-pop.tox-pop--right:after {
  border-width: 8px;
  border-color: #0000 #0000 #0000 #fff;
  margin-left: -1px;
}

.tox .tox-pop.tox-pop--right:before {
  border-width: 10px;
  border-color: #0000 #0000 #0000 #eee;
  margin-left: -1px;
}

.tox .tox-pop.tox-pop--align-left:after, .tox .tox-pop.tox-pop--align-left:before {
  left: 20px;
}

.tox .tox-pop.tox-pop--align-right:after, .tox .tox-pop.tox-pop--align-right:before {
  left: calc(100% - 20px);
}

.tox .tox-sidebar-wrap {
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
  display: flex;
}

.tox .tox-sidebar {
  background-color: #fff;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.tox .tox-sidebar__slider {
  display: flex;
  overflow: hidden;
}

.tox .tox-sidebar__pane-container, .tox .tox-sidebar__pane {
  display: flex;
}

.tox .tox-sidebar--sliding-closed {
  opacity: 0;
}

.tox .tox-sidebar--sliding-open {
  opacity: 1;
}

.tox .tox-sidebar--sliding-growing, .tox .tox-sidebar--sliding-shrinking {
  transition: width .5s, opacity .5s;
}

.tox .tox-selector {
  box-sizing: border-box;
  background-color: #4099ff;
  border: 1px solid #4099ff;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
}

.tox.tox-platform-touch .tox-selector {
  width: 12px;
  height: 12px;
}

.tox .tox-slider {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 24px;
  display: flex;
  position: relative;
}

.tox .tox-slider__rail {
  background-color: #0000;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  min-width: 120px;
  height: 10px;
}

.tox .tox-slider__handle {
  box-shadow: none;
  background-color: #006ce7;
  border: 2px solid #0054b4;
  border-radius: 6px;
  width: 14px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.tox .tox-form__controls-h-stack > .tox-slider:not(:first-of-type) {
  margin-inline-start: 8px;
}

.tox .tox-form__controls-h-stack > .tox-form__group + .tox-slider, .tox .tox-form__controls-h-stack > .tox-slider + .tox-form__group {
  margin-inline-start: 32px;
}

.tox .tox-source-code {
  overflow: auto;
}

.tox .tox-spinner {
  display: flex;
}

.tox .tox-spinner > div {
  background-color: #222f3eb3;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  animation: 1.5s ease-in-out infinite both tam-bouncing-dots;
}

.tox .tox-spinner > div:first-child {
  animation-delay: -.32s;
}

.tox .tox-spinner > div:nth-child(2) {
  animation-delay: -.16s;
}

@keyframes tam-bouncing-dots {
  0%, 100%, 80% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.tox:not([dir="rtl"]) .tox-spinner > div:not(:first-child) {
  margin-left: 4px;
}

.tox[dir="rtl"] .tox-spinner > div:not(:first-child) {
  margin-right: 4px;
}

.tox .tox-statusbar {
  color: #222f3eb3;
  text-transform: none;
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  flex: none;
  align-items: center;
  height: 25px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tox .tox-statusbar__path {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  display: flex;
  overflow: hidden;
}

.tox .tox-statusbar__right-container {
  white-space: nowrap;
  justify-content: flex-end;
  display: flex;
}

.tox .tox-statusbar__help-text {
  text-align: center;
}

.tox .tox-statusbar__text-container {
  flex: auto;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

@media only screen and (width >= 768px) {
  .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__help-text, .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__path, .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__right-container {
    flex: 0 0 33.3333%;
  }
}

.tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-end {
  justify-content: flex-end;
}

.tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-start {
  justify-content: flex-start;
}

.tox .tox-statusbar__text-container.tox-statusbar__text-container--space-around {
  justify-content: space-around;
}

.tox .tox-statusbar__path > * {
  white-space: nowrap;
  display: inline;
}

.tox .tox-statusbar__wordcount {
  flex: none;
  margin-left: 1ch;
}

@media only screen and (width <= 767px) {
  .tox .tox-statusbar__text-container .tox-statusbar__help-text {
    display: none;
  }

  .tox .tox-statusbar__text-container .tox-statusbar__help-text:only-child {
    display: block;
  }
}

.tox .tox-statusbar a, .tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount {
  color: #222f3eb3;
  text-decoration: none;
}

.tox .tox-statusbar a:focus:not(:disabled):not([aria-disabled="true"]), .tox .tox-statusbar a:hover:not(:disabled):not([aria-disabled="true"]), .tox .tox-statusbar__path-item:focus:not(:disabled):not([aria-disabled="true"]), .tox .tox-statusbar__path-item:hover:not(:disabled):not([aria-disabled="true"]), .tox .tox-statusbar__wordcount:focus:not(:disabled):not([aria-disabled="true"]), .tox .tox-statusbar__wordcount:hover:not(:disabled):not([aria-disabled="true"]) {
  color: #222f3e;
  cursor: pointer;
}

.tox .tox-statusbar__branding svg {
  fill: #222f3ecc;
  vertical-align: -.28em;
  width: 3.6em;
  height: 1.14em;
}

.tox .tox-statusbar__branding a:focus:not(:disabled):not([aria-disabled="true"]) svg, .tox .tox-statusbar__branding a:hover:not(:disabled):not([aria-disabled="true"]) svg {
  fill: #222f3e;
}

.tox .tox-statusbar__resize-handle {
  cursor: nwse-resize;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  margin-left: auto;
  margin-right: -8px;
  padding-bottom: 3px;
  padding-left: 1ch;
  padding-right: 3px;
  display: flex;
}

.tox .tox-statusbar__resize-handle svg {
  fill: #222f3e80;
  display: block;
}

.tox .tox-statusbar__resize-handle:focus svg {
  background-color: #dee0e2;
  border-radius: 1px 1px 5px;
  box-shadow: 0 0 0 2px #dee0e2;
}

.tox:not([dir="rtl"]) .tox-statusbar__path > * {
  margin-right: 4px;
}

.tox:not([dir="rtl"]) .tox-statusbar__branding {
  margin-left: 2ch;
}

.tox[dir="rtl"] .tox-statusbar {
  flex-direction: row-reverse;
}

.tox[dir="rtl"] .tox-statusbar__path > * {
  margin-left: 4px;
}

.tox .tox-throbber {
  z-index: 1299;
}

.tox .tox-throbber__busy-spinner {
  background-color: #fff9;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.tox .tox-tbtn {
  box-shadow: none;
  color: #222f3e;
  text-transform: none;
  background: none;
  border: 0;
  border-radius: 3px;
  outline: 0;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 28px;
  margin: 6px 1px 5px 0;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  overflow: hidden;
}

.tox .tox-tbtn svg {
  fill: #222f3e;
  display: block;
}

.tox .tox-tbtn.tox-tbtn-more {
  width: inherit;
  padding-left: 5px;
  padding-right: 5px;
}

.tox .tox-tbtn:focus {
  box-shadow: none;
  background: #cce2fa;
  border: 0;
}

.tox .tox-tbtn:hover {
  box-shadow: none;
  color: #222f3e;
  background: #cce2fa;
  border: 0;
}

.tox .tox-tbtn:hover svg {
  fill: #222f3e;
}

.tox .tox-tbtn:active {
  box-shadow: none;
  color: #222f3e;
  background: #a6ccf7;
  border: 0;
}

.tox .tox-tbtn:active svg {
  fill: #222f3e;
}

.tox .tox-tbtn--disabled .tox-tbtn--enabled svg {
  fill: #222f3e80;
}

.tox .tox-tbtn--disabled, .tox .tox-tbtn--disabled:hover, .tox .tox-tbtn:disabled, .tox .tox-tbtn:disabled:hover {
  box-shadow: none;
  color: #222f3e80;
  cursor: not-allowed;
  background: none;
  border: 0;
}

.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg {
  fill: #222f3e80;
}

.tox .tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover {
  box-shadow: none;
  color: #222f3e;
  background: #a6ccf7;
  border: 0;
}

.tox .tox-tbtn--enabled:hover > *, .tox .tox-tbtn--enabled > * {
  transform: none;
}

.tox .tox-tbtn--enabled svg, .tox .tox-tbtn--enabled:hover svg {
  fill: #222f3e;
}

.tox .tox-tbtn--enabled.tox-tbtn--disabled svg, .tox .tox-tbtn--enabled:hover.tox-tbtn--disabled svg {
  fill: #222f3e80;
}

.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
  color: #222f3e;
}

.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
  fill: #222f3e;
}

.tox .tox-tbtn:active > * {
  transform: none;
}

.tox .tox-tbtn--md {
  width: 51px;
  height: 42px;
}

.tox .tox-tbtn--lg {
  flex-direction: column;
  width: 68px;
  height: 56px;
}

.tox .tox-tbtn--return {
  height: unset;
  align-self: stretch;
  width: 16px;
}

.tox .tox-tbtn--labeled {
  width: unset;
  padding: 0 4px;
}

.tox .tox-tbtn__vlabel {
  letter-spacing: -.025em;
  white-space: nowrap;
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 400;
  display: block;
}

.tox .tox-number-input {
  border-radius: 3px;
  width: auto;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
  display: flex;
}

.tox .tox-number-input .tox-input-wrapper {
  pointer-events: none;
  text-align: center;
  background: #f7f7f7;
  display: flex;
}

.tox .tox-number-input .tox-input-wrapper:focus {
  background: #cce2fa;
}

.tox .tox-number-input input {
  color: #222f3e;
  pointer-events: all;
  border-radius: 3px;
  width: 60px;
  margin: 2px 0;
  font-size: 14px;
}

.tox .tox-number-input input:hover {
  color: #222f3e;
  background: #cce2fa;
}

.tox .tox-number-input input:focus {
  color: #222f3e;
  background: #fff;
}

.tox .tox-number-input input:disabled {
  box-shadow: none;
  color: #222f3e80;
  cursor: not-allowed;
  background: none;
  border: 0;
}

.tox .tox-number-input button {
  color: #222f3e;
  text-align: center;
  background: #f7f7f7;
  width: 24px;
  height: 28px;
}

.tox .tox-number-input button svg {
  fill: #222f3e;
  margin: 0 auto;
  display: block;
  transform: scale(.67);
}

.tox .tox-number-input button:focus {
  background: #cce2fa;
}

.tox .tox-number-input button:hover {
  box-shadow: none;
  color: #222f3e;
  background: #cce2fa;
  border: 0;
}

.tox .tox-number-input button:hover svg {
  fill: #222f3e;
}

.tox .tox-number-input button:active {
  box-shadow: none;
  color: #222f3e;
  background: #a6ccf7;
  border: 0;
}

.tox .tox-number-input button:active svg {
  fill: #222f3e;
}

.tox .tox-number-input button:disabled {
  box-shadow: none;
  color: #222f3e80;
  cursor: not-allowed;
  background: none;
  border: 0;
}

.tox .tox-number-input button:disabled svg {
  fill: #222f3e80;
}

.tox .tox-number-input button.minus {
  border-radius: 3px 0 0 3px;
}

.tox .tox-number-input button.plus {
  border-radius: 0 3px 3px 0;
}

.tox .tox-number-input:focus:not(:active) > .tox-input-wrapper, .tox .tox-number-input:focus:not(:active) > button {
  background: #cce2fa;
}

.tox .tox-tbtn--select {
  width: auto;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
}

.tox .tox-tbtn__select-label {
  cursor: default;
  height: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 4px;
  font-weight: 400;
  overflow: hidden;
}

.tox .tox-tbtn__select-chevron {
  justify-content: center;
  align-items: center;
  width: 16px;
  display: flex;
}

.tox .tox-tbtn__select-chevron svg {
  fill: #222f3e80;
}

.tox .tox-tbtn--bespoke {
  background: #f7f7f7;
}

.tox .tox-tbtn--bespoke + .tox-tbtn--bespoke {
  margin-inline-start: 4px;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 7em;
  overflow: hidden;
}

.tox .tox-tbtn--disabled .tox-tbtn__select-label, .tox .tox-tbtn--select:disabled .tox-tbtn__select-label {
  cursor: not-allowed;
}

.tox .tox-split-button {
  box-sizing: border-box;
  border: 0;
  border-radius: 3px;
  margin: 6px 1px 5px 0;
  display: flex;
  overflow: hidden;
}

.tox .tox-split-button:hover {
  box-shadow: inset 0 0 0 1px #cce2fa;
}

.tox .tox-split-button:focus {
  box-shadow: none;
  color: #222f3e;
  background: #cce2fa;
}

.tox .tox-split-button > * {
  border-radius: 0;
}

.tox .tox-split-button__chevron {
  width: 16px;
}

.tox .tox-split-button__chevron svg {
  fill: #222f3e80;
}

.tox .tox-split-button .tox-tbtn {
  margin: 0;
}

.tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:focus, .tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:hover, .tox .tox-split-button.tox-tbtn--disabled:focus, .tox .tox-split-button.tox-tbtn--disabled:hover {
  box-shadow: none;
  color: #222f3e80;
  background: none;
}

.tox.tox-platform-touch .tox-split-button .tox-tbtn--select {
  padding: 0;
}

.tox.tox-platform-touch .tox-split-button .tox-tbtn:not(.tox-tbtn--select):first-child {
  width: 30px;
}

.tox.tox-platform-touch .tox-split-button__chevron {
  width: 20px;
}

.tox .tox-split-button.tox-tbtn--disabled svg #tox-icon-highlight-bg-color__color, .tox .tox-split-button.tox-tbtn--disabled svg #tox-icon-text-color__color {
  opacity: .6;
}

.tox .tox-toolbar-overlord {
  background-color: #fff;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  background-color: #fff;
  background-image: repeating-linear-gradient(#e3e3e3 0 1px, #0000 1px 39px);
  background-position: 50% 40px;
  background-repeat: no-repeat;
  background-size: calc(100% - 22px) calc(100% - 41px);
  background-attachment: local;
  flex-wrap: wrap;
  flex: none;
  padding: 0;
  display: flex;
  transform: perspective(1px);
}

.tox .tox-toolbar-overlord > .tox-toolbar, .tox .tox-toolbar-overlord > .tox-toolbar__overflow, .tox .tox-toolbar-overlord > .tox-toolbar__primary {
  background-position: 50% 0;
  background-size: calc(100% - 22px) 100%;
}

.tox .tox-toolbar__overflow.tox-toolbar__overflow--closed {
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.tox .tox-toolbar__overflow--growing {
  transition: height .3s, opacity .2s linear .1s;
}

.tox .tox-toolbar__overflow--shrinking {
  transition: opacity .3s, height .2s linear .1s, visibility 0s linear .3s;
}

.tox .tox-anchorbar, .tox .tox-toolbar-overlord {
  grid-column: 1 / -1;
}

.tox .tox-menubar + .tox-toolbar, .tox .tox-menubar + .tox-toolbar-overlord {
  border-top: 1px solid #0000;
  margin-top: -1px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.tox .tox-toolbar--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.tox .tox-pop .tox-toolbar {
  border-width: 0;
}

.tox .tox-toolbar--no-divider {
  background-image: none;
}

.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child, .tox .tox-toolbar-overlord .tox-toolbar__primary {
  background-position: 50% 39px;
}

.tox .tox-editor-header > .tox-toolbar--scrolling, .tox .tox-toolbar-overlord .tox-toolbar--scrolling:first-child {
  background-image: none;
}

.tox.tox-tinymce-aux .tox-toolbar__overflow {
  overscroll-behavior: none;
  background-color: #fff;
  background-position: 50% 43px;
  background-size: calc(100% - 16px) calc(100% - 51px);
  border: none;
  border-radius: 6px;
  padding: 4px 0;
  box-shadow: 0 0 2px #222f3e33, 0 4px 8px #222f3e26;
}

.tox-pop .tox-pop__dialog .tox-toolbar {
  background-position: 50% 43px;
  background-size: calc(100% - 22px) calc(100% - 51px);
  padding: 4px 0;
}

.tox .tox-toolbar__group {
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0 11px 0 12px;
  display: flex;
}

.tox .tox-toolbar__group--pull-right {
  margin-left: auto;
}

.tox .tox-toolbar--scrolling .tox-toolbar__group {
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #0000;
}

.tox[dir="rtl"] .tox-toolbar__group:not(:last-of-type) {
  border-left: 1px solid #0000;
}

.tox .tox-tooltip {
  padding: 8px;
  display: inline-block;
  position: relative;
}

.tox .tox-tooltip__body {
  color: #ffffffbf;
  text-transform: none;
  background-color: #222f3e;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  box-shadow: 0 2px 4px #222f3e4d;
}

.tox .tox-tooltip__arrow {
  position: absolute;
}

.tox .tox-tooltip--down .tox-tooltip__arrow {
  border-top: 8px solid #222f3e;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tox .tox-tooltip--up .tox-tooltip__arrow {
  border-bottom: 8px solid #222f3e;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tox .tox-tooltip--right .tox-tooltip__arrow {
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  border-left: 8px solid #222f3e;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.tox .tox-tooltip--left .tox-tooltip__arrow {
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  border-right: 8px solid #222f3e;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tox .tox-tree {
  flex-direction: column;
  display: flex;
}

.tox .tox-tree .tox-trbtn {
  box-shadow: none;
  color: #222f3e;
  text-transform: none;
  background: none;
  border: 0;
  border-radius: 4px;
  outline: 0;
  flex: none;
  align-items: center;
  height: 28px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 0 0 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  overflow: hidden;
}

.tox .tox-tree .tox-trbtn .tox-tree__label {
  cursor: default;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tox .tox-tree .tox-trbtn svg {
  fill: #222f3e;
  display: block;
}

.tox .tox-tree .tox-trbtn:focus {
  box-shadow: none;
  background: #cce2fa;
  border: 0;
}

.tox .tox-tree .tox-trbtn:hover {
  box-shadow: none;
  color: #222f3e;
  background: #cce2fa;
  border: 0;
}

.tox .tox-tree .tox-trbtn:hover svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:active {
  box-shadow: none;
  color: #222f3e;
  background: #a6ccf7;
  border: 0;
}

.tox .tox-tree .tox-trbtn:active svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn--disabled, .tox .tox-tree .tox-trbtn--disabled:hover, .tox .tox-tree .tox-trbtn:disabled, .tox .tox-tree .tox-trbtn:disabled:hover {
  box-shadow: none;
  color: #222f3e80;
  cursor: not-allowed;
  background: none;
  border: 0;
}

.tox .tox-tree .tox-trbtn--disabled svg, .tox .tox-tree .tox-trbtn--disabled:hover svg, .tox .tox-tree .tox-trbtn:disabled svg, .tox .tox-tree .tox-trbtn:disabled:hover svg {
  fill: #222f3e80;
}

.tox .tox-tree .tox-trbtn--enabled, .tox .tox-tree .tox-trbtn--enabled:hover {
  box-shadow: none;
  color: #222f3e;
  background: #a6ccf7;
  border: 0;
}

.tox .tox-tree .tox-trbtn--enabled:hover > *, .tox .tox-tree .tox-trbtn--enabled > * {
  transform: none;
}

.tox .tox-tree .tox-trbtn--enabled svg, .tox .tox-tree .tox-trbtn--enabled:hover svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:focus:not(.tox-trbtn--disabled) {
  color: #222f3e;
}

.tox .tox-tree .tox-trbtn:focus:not(.tox-trbtn--disabled) svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-trbtn:active > * {
  transform: none;
}

.tox .tox-tree .tox-trbtn--return {
  height: unset;
  align-self: stretch;
  width: 16px;
}

.tox .tox-tree .tox-trbtn--labeled {
  width: unset;
  padding: 0 4px;
}

.tox .tox-tree .tox-trbtn__vlabel {
  letter-spacing: -.025em;
  white-space: nowrap;
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 400;
  display: block;
}

.tox .tox-tree .tox-tree--directory {
  flex-direction: column;
  display: flex;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label {
  font-weight: 700;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn {
  margin-left: auto;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn svg {
  fill: #0000;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn.tox-mbtn--active svg, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn:focus svg, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:focus .tox-mbtn svg, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover .tox-mbtn svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover:has(.tox-mbtn:hover) {
  color: #222f3e;
  background-color: #0000;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover:has(.tox-mbtn:hover) .tox-chevron svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-chevron {
  margin-right: 6px;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has( + .tox-tree--directory__children--growing) .tox-chevron, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has( + .tox-tree--directory__children--shrinking) .tox-chevron {
  transition: transform .5s ease-in-out;
}

.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has( + .tox-tree--directory__children--growing) .tox-chevron, .tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has( + .tox-tree--directory__children--open) .tox-chevron {
  transform: rotate(90deg);
}

.tox .tox-tree .tox-tree--leaf__label {
  font-weight: 400;
}

.tox .tox-tree .tox-tree--leaf__label .tox-mbtn {
  margin-left: auto;
}

.tox .tox-tree .tox-tree--leaf__label .tox-mbtn svg {
  fill: #0000;
}

.tox .tox-tree .tox-tree--leaf__label .tox-mbtn.tox-mbtn--active svg, .tox .tox-tree .tox-tree--leaf__label .tox-mbtn:focus svg, .tox .tox-tree .tox-tree--leaf__label:hover .tox-mbtn svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--leaf__label:hover:has(.tox-mbtn:hover) {
  color: #222f3e;
  background-color: #0000;
}

.tox .tox-tree .tox-tree--leaf__label:hover:has(.tox-mbtn:hover) .tox-chevron svg {
  fill: #222f3e;
}

.tox .tox-tree .tox-tree--directory__children {
  padding-left: 16px;
  overflow: hidden;
}

.tox .tox-tree .tox-tree--directory__children.tox-tree--directory__children--growing, .tox .tox-tree .tox-tree--directory__children.tox-tree--directory__children--shrinking {
  transition: height .5s ease-in-out;
}

.tox .tox-tree .tox-trbtn.tox-tree--leaf__label {
  justify-content: space-between;
  display: flex;
}

.tox .tox-view-wrap, .tox .tox-view-wrap__slot-container {
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.tox .tox-view {
  flex-direction: column;
  flex: auto;
  display: flex;
  overflow: hidden;
}

.tox .tox-view__header {
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0;
  font-size: 16px;
  display: flex;
  position: relative;
}

.tox .tox-view--mobile.tox-view__header, .tox .tox-view--mobile.tox-view__toolbar {
  padding: 8px;
}

.tox .tox-view--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.tox .tox-view__toolbar {
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 8px 0;
  display: flex;
}

.tox .tox-view__toolbar__group {
  flex-direction: row;
  gap: 12px;
  display: flex;
}

.tox .tox-view__header-end, .tox .tox-view__header-start {
  display: flex;
}

.tox .tox-view__pane {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.tox .tox-view__pane_panel {
  border: 1px solid #eee;
  border-radius: 6px;
}

.tox:not([dir="rtl"]) .tox-view__header .tox-view__header-end > *, .tox:not([dir="rtl"]) .tox-view__header .tox-view__header-start > * {
  margin-left: 8px;
}

.tox[dir="rtl"] .tox-view__header .tox-view__header-end > *, .tox[dir="rtl"] .tox-view__header .tox-view__header-start > * {
  margin-right: 8px;
}

.tox .tox-well {
  border: 1px solid #eee;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
}

.tox .tox-well > :first-child {
  margin-top: 0;
}

.tox .tox-well > :last-child {
  margin-bottom: 0;
}

.tox .tox-well > :only-child {
  margin: 0;
}

.tox .tox-custom-editor {
  border: 1px solid #eee;
  border-radius: 6px;
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tox .tox-dialog-loading:before {
  content: "";
  z-index: 1000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
}

.tox .tox-tab {
  cursor: pointer;
}

.tox .tox-dialog__content-js, .tox .tox-dialog__body-content .tox-collection {
  flex: 1;
  display: flex;
}
/*# sourceMappingURL=index.9b485f57.css.map */
